/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../../assets/css/sidebar.css";
import React from "react";
import { useNavigate } from "react-router";
import Icon from "../../../assets/icons/energy-icon.svg";
import ActiveIcon from "../../../assets/icons/energy-icon-active.svg";

const SideBar = ({ closeNav }) => {
  const navigate = useNavigate();
  const page = window.location.pathname;

  const redirectInTransactionHistory = () => {
    window.open(process.env.REACT_APP_TRANSACTION_URL, "_blank", "noreferrer");
  }

  const handleChangeTab = (route) => {
    navigate(route)
    closeNav();
  }

  return (
    <>
      <div className={`nav-link ${page === "/dashboard" ? "font-00A14B" : "font-5F738C"} p-2`} activeclassname="active" onClick={() => handleChangeTab("/dashboard")}>
        <i className="fal fa-signal-alt-2 font-30"></i>
        <br />
        <small className="font-12">Dashboard</small>
      </div >
      <div className={`nav-link ${page === "/wallet-address" ? "font-00A14B" : "font-5F738C"} p-2`} activeclassname="active" onClick={() => handleChangeTab("/wallet-address")}>
        <i className="fal fa-wallet font-30"></i>
        <br />
        <small className="font-12">Wallet</small>
      </div >
      <div className={`nav-link ${page === "/greenDataCenterNft" ? "font-00A14B" : "font-5F738C"} p-2`} activeclassname="active" onClick={() => handleChangeTab("/greenDataCenterNft")}>
        {page === "/greenDataCenterNft" ? (
          <img src={ActiveIcon} height={45} alt="" />
        ) : (
          <img src={Icon} height={45} alt="" />
        )}
        <br />
        <small className="font-12">Green Data Center NFT</small>
      </div >

      <div className="nav-link font-5F738C p-2" onClick={() => redirectInTransactionHistory()}>
        <i className="fal fa-sitemap font-30"></i>
        <br />
        <small className="font-12">Transaction History</small>
      </div>

      <div className={`nav-link ${page === "/about" ? "font-00A14B" : "font-5F738C"} p-2`} activeclassname="active" onClick={() => handleChangeTab("/about")}>
        <i className="fal fa-info-circle font-30"></i>
        <br />
        <small className="font-12">About</small>
      </div>
    </>
  );
};

export default SideBar;
