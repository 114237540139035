import React from "react";
import { useNavigate } from "react-router";
import { TAB } from "../../app/constants/constants";

const Tabs = ({ setPage }) => {
  const navigate = useNavigate();

  const handleChange = (page) => {
    setPage(page);
    if (page === TAB.ABOUT) {
      navigate('/about')
    }
  };

  return (
    <ul className="nav nav-tabs mb-3 gap-2 gap-lg-3 border-bottom-0" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          onClick={() => handleChange(TAB.GREENDATACENTER)}
          className="nav-link active btn btn-primary"
          id="energy-nft"
          data-bs-toggle="tab"
          data-bs-target="#energy-nft-pane"
          type="button"
          role="tab"
          aria-controls="home-tab-pane"
          aria-selected="true"
        >
          Green Data Center NFT
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          onClick={() => handleChange(TAB.ABOUT)}
          className="nav-link btn btn-primary"
          id="nature-nft"
          data-bs-toggle="tab"
          data-bs-target="#nature-nft-pane"
          type="button"
          role="tab"
          aria-controls="profile-tab-pane"
          aria-selected="false"
        >
          About
        </button>
      </li>
    </ul>
  );
};

export default Tabs;
