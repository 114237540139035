import '../../assets/css/terms-and-condition-popup.css';
import React from 'react';

const TermsAndCondition = ({ hide, acceptTermsAndCondition, termsAndConditionIsLoading }) => {

  const handleCancel = () => {
    hide();
  }

  return (
    <div className="container">
      <div className="header">Terms And Condition</div>
      <div className='terms-and-condition'>
        <div className="title">
          1.1 The Token Offering
        </div>
        <div className="description">
          <p>
            Sunset Capital is pleased to present an innovative investment opportunity through the issuance of a Non-Fungible
            Token (NFT), designed to raise capital for the tokenization of a diversified portfolio of green datacenter assets situated
            in Brazil. These assets are currently under distressed financial conditions, presenting a unique investment
            opportunity.
          </p>
          <p>
            This NFT offering aims to attract a wide array of investors by leveraging the expansive global liquidity associated
            with Initial Coin Offerings (ICOs) alongside traditional financial and capital market participants. The Sunset Green Datacenter
            NFT is structured to be fully compliant with all Know Your Customer (KYC), Anti-Money Laundering (AML)
            regulations, tax obligations, and securities regulations, ensuring a secure and legally sound investment.
          </p>
          <p>
            We anticipate that the Tokens (as defined below) will be available for trading in the secondary market in the near
            future, providing liquidity and flexibility for investors.
          </p>
          <p>
            For prospective purchasers, the NFT (i) brings a unique opportunity to access, through a low-cost/tax-efficient
            structure, the Brazilian distressed green datacenter market, (one of the fastest-growing markets of Latin America), (ii)
            relies on the expertise, proven track record and credibility of the Sunset Capital team and (iii) is conducted through
            a simple, direct and innovative way using a blockchain-based technology asset known as “Sunset Energy” (the
            “Token”).&quot;
          </p>
        </div>
      </div>

      <div className='terms-and-condition'>
        <div className="title">
          1.2 Eligibility
        </div>
        <div className="description">
          <p>
            Sunset Capital (“Issuer”) is a company duly incorporated under both the Switzerland Foundation Companies Law
            and the Brazilian Companies Law. As such, Sunset Capital is committed to adhering to the stringent regulatory
            frameworks and compliance obligations of both jurisdictions. This includes rigorous Anti-Money Laundering (“AML”)
            and Know Your Customer (“KYC”) procedures in accordance with the Proceeds of Crime Law (Revised) and the
            Anti-Money Laundering Regulations (Revised).
          </p>
          <p>
            Sunset Capital is fully compliant with the AML and KYC requirements of both Brazil and Switzerland. These
            regulations mandate thorough due diligence processes to prevent money laundering, terrorism financing, and other
            illicit activities.
          </p>
          <p>
            This Non-Fungible Token Offering (NFT) is designed for international purchasers, with certain exclusions to ensure
            compliance with global regulatory standards. Specifically, the NFT excludes:
          </p>
          <p>
            <b>Individuals with Residence/Nationality Restrictions:</b> Persons residing in or holding nationality from countries
            where the purchase of cryptocurrencies is legally prohibited.
          </p>
          <p>
            <b>Blacklisted Jurisdictions:</b> Persons located in jurisdictions that are blacklisted by the Organization for Economic
            Co-operation and Development (OECD) and the United Nations (UN).
          </p>
        </div>
      </div>

      <div className='terms-and-condition'>
        <div className="title">
          1.3 Legal Disclosures
        </div>
        <div className="description">
          <p>
            This Whitepaper provides detailed information regarding an opportunity to acquire a non-fungible token (NFT) that
            offers purchasers economic exposure to the Brazilian green energy market under distressed conditions (the &quot;Target
            Assets&quot;) through periodic profit distributions.
          </p>
          <p>
            The Tokens will not (i) provide legal ownership over the Issuer’s shares or the Target Assets; (ii) represent debt
            owed by the issuer to the Tokenholders; nor (iii) provide voting/governance/typical shareholding rights related to the
            Issuer.
          </p>
          <p>
            This Whitepaper does not constitute a prospectus, an offering memorandum, or any other offering document related
            to the Issuer and has not been reviewed or approved by any financial regulator or securities commission in any
            jurisdiction.
          </p>
          <p>
            Investing in Tokens involves several risks. There can be no assurance that Tokenholders will be able to recoup
            their capital or realize any positive returns on their purchase of Tokens. Prospective purchasers are strongly
            advised to carefully review the &quot;Risk Factors&quot; section of this Whitepaper. While this section does not provide an
            exhaustive list or comprehensive explanation of all risks associated with investing in Tokens, it serves as a crucial guide for potential investors. Prospective purchasers should thoroughly assess whether purchasing Tokens aligns
            with their personal legal and financial circumstances, taking into account all the information presented herein.
            Unless otherwise indicated or unless the context requires otherwise, all references in this Whitepaper to &quot;Issuer,&quot;
            &quot;we,&quot; &quot;our,&quot; &quot;ours,&quot; &quot;us,&quot; or similar terms refer to the Issuer.
          </p>
          <p>
            We encourage all potential investors to conduct their own due diligence and consult with their legal and financial
            advisors before making any investment decisions. This document is for informational purposes only.
          </p>
        </div>
      </div>

      <div className='terms-and-condition'>
        <div className="title">
          1.4 Forward-Looking Statements
        </div>
        <div className="description">
          <p>
            This Whitepaper may contain estimates and forward-looking statements which are mainly based on the current
            expectations and estimates of future events and trends that affect or may affect the business, financial condition,
            results of operations, cash flows, liquidity, prospects, and the envisaged valuation of the Tokens. Although we
            believe that these estimates and forward-looking statements are based upon reasonable assumptions, they are
            subject to many significant risks and uncertainties and are made in light of the current available information.
            Forward-looking statements speak only as of the date they were made, and we do not undertake the obligation to
            update publicly or to revise any forward-looking statements after we distribute this document because of new
            information, future events, or other factors. Considering the risks and uncertainties described above, the forward-
            looking events and circumstances discussed in this document might not occur and future results may be materially
            different from those expressed in or suggested by these forward-looking statements.
          </p>
          <p>
            Forward-looking statements involve known and unknown risks, uncertainties and other factors which may cause
            actual events or results, performance, or achievements to differ materially from the estimates or the results implied
            or expressed in such forward-looking statements. These factors include, amongst others:
          </p>
          <p>
            A - changes in political, social, economic, and stock or cryptocurrency market conditions, and the regulatory
            environment in the countries in which the Issuer conducts its businesses and operations.
          </p>
          <p>
            B - the risk that the Issuer may be unable to execute or implement its respective business strategy and future plans.
          </p>
          <p>
            C - changes in interest rates and exchange rates of fiat currencies and cryptocurrencies.
          </p>
          <p>
            D - changes in the anticipated growth strategies and expected internal growth of the Issuer.
          </p>
          <p>
            E - changes in the availability and salaries of employees who are required by the Issuer to operate their respective
            businesses and operations.
          </p>
          <p>
            F - changes in competitive conditions under which the Issuer operates, and the ability of the Issuer to compete
            under such conditions.
          </p>
          <p>
            G - changes in the future capital needs of the Issuer and the availability of financing and capital to fund such needs.
          </p>
          <p>
            H - war or acts of international or domestic terrorism.
          </p>
          <p>
            I - occurrences of catastrophic events, natural disasters and acts of God that affect the businesses and/or
            operations of the Issuer; and
          </p>
          <p>
            J - other factors beyond the control of the Issuer.
          </p>
          <p>
            The Issuer disclaims any responsibility to update any of those forward-looking statements or publicly announce any
            revisions to those forward-looking statements to reflect future developments, events or circumstances, even if new
            information becomes available or other events occur in the future.
          </p>
        </div>
      </div>

      <div className='terms-and-condition'>
        <div className="title">
          1.5 Accuracy of Information, No Consent of Parties Referenced in Whitepaper
        </div>
        <div className="description">
          <p>
            This Whitepaper includes technical, market, and industry information and forecasts that have been derived from
            internal surveys, reports, and studies, as well as market and academic research, publicly available information, and
            industry publications. These sources generally state that the information they contain is obtained from sources
            believed to be reliable. However, there can be no assurance as to the accuracy or completeness of the information
            included herein.
          </p>
          <p>
            Except for the Issuer and its respective directors, executive officers, and employees, no individual has consented to
            the inclusion of their name, or any other information attributed or perceived to be attributed to them in this
            Whitepaper. No representation, warranty, or undertaking is or is purported to be provided as to the accuracy or
            completeness of such information by such persons, and they are not obliged to provide any updates to said
            information.
          </p>
          <p>
            The Issuer has not conducted an independent review of information extracted from third-party sources, verified its
            accuracy or completeness, or ascertained the underlying assumptions relied upon therein. Consequently, the Issuer
            makes no representation or warranty as to the accuracy or completeness of such information and shall not be
            obliged to provide any updates to said information.
          </p>
          <p>
            Except as otherwise mentioned below, all data and information from the charts were provided by the Sunset Capital
            Research team.
          </p>
        </div>
      </div>

      <div className='terms-and-condition'>
        <div className="title">
          1.6 Terms Used
        </div>
        <div className="description">
          <p>
            To facilitate a better understanding of the Tokens being offered for purchase by the Issuer, and the businesses and
            operations of the Issuer, certain technical terms and abbreviations, along with their descriptions, have been used in
            this Whitepaper. These descriptions and assigned meanings should not be considered definitive and may not
            correspond to standard industry meanings or usage.
          </p>
          <p>
            Words importing the singular shall, where applicable, include the plural and vice versa. Words importing the
            masculine gender shall, where applicable, include the feminine and neuter genders and vice versa. References to
            persons shall include corporations.
          </p>
        </div>
      </div>

      <div className='terms-and-condition'>
        <div className="title">
          1.7 No Further Information or Update
        </div>
        <div className="description">
          <p>
            No person has been or is authorized to provide any information or make any representation not contained in this
            Whitepaper in connection with the Issuer, its business and operations, or the Tokens. If any such information or
            representation is provided, it must not be relied upon as having been authorized by or on behalf of the Issuer.
          </p>
          <p>
            The ongoing sale of Tokens shall not, under any circumstances, constitute a continuing representation or imply that
            there have been no changes or developments likely to involve a material change in the affairs, conditions, and
            prospects of the Issuer or in any statement of fact or information contained in the Whitepaper since the date of
            issuance.
          </p>
          <p>
            Statements made in this Whitepaper are based on the laws and practices in Brazil current at the date it was issued.
            These statements are subject to change if the applicable laws or practices change. The delivery of this Whitepaper
            or the sale of Tokens does not imply or represent that the affairs of the Issuer have remained unchanged since the
            date of the Whitepaper.
          </p>
          <p>
            Any applicants to the NFT, whether in a primary issuance or in the secondary market, will be subject to all
            applicable Know Your Customer (KYC) and Anti-Money Laundering (AML) policies in place at the time of purchase.
            These policies are subject to periodic assessment and review.
          </p>
          <p>
            Failure to comply with the KYC/AML procedures and routines applicable to the purchase of Tokens will result in the
            prevention of the purchase or the imposition of sanctions on purchasers. Such sanctions may include, but are not
            limited to, the freezing of funds, mandatory cancellation, or redemption of Tokens through our smart contract, or any
            other measures the Issuer deems appropriate to meet applicable regulatory requirements.
          </p>
        </div>
      </div>

      <div className='terms-and-condition'>
        <div className="title">
          Sunset NFT Offering
        </div>
        <div className="description">
          <p>
            The Sunset Green Datacenter NFT represents a groundbreaking opportunity to invest in distressed green datacenter assets in
            Brazil. By combining the global reach of ICO liquidity with traditional investment mechanisms, and backed by a
            robust compliance framework, this offering stands out as a forward-thinking approach to sustainable investment.
            We invite prospective investors to explore this unique opportunity to participate in the growth and development of
            the green energy sector in Latin America through a secure and innovative blockchain-based asset.
          </p>
          <p>
            For further details, please contact the Sunset Capital team or visit our official website.
          </p>
          <p>
            This document is intended for informational purposes only and does not constitute an offer to sell or a solicitation of
            an offer to buy securities. Investors should conduct their own due diligence and consult with their legal and financial
            advisors before making any investment decisions.
          </p>
          <p>
            Sunset Capital remains dedicated to fostering a secure and compliant investment environment, aligning with
            international best practices and regulatory standards.
          </p>
        </div>
      </div>

      <div className='button'>
        {
          termsAndConditionIsLoading ?
            <button type="submit" className="btn mx-auto" style={{ backgroundColor: "#186200", borderColor: "#186200" }} disabled>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </button>
            :
            <button type="submit" className="btn btn-darkgreen btn-extra-size" onClick={acceptTermsAndCondition}>
              I Agree
            </button>
        }
        <button className="btn cancel-button btn-extra-size" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  )
}

export default TermsAndCondition;